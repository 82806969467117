<template>
  <div>
    <MasterHeader />
    <!-- <sign-up-modal v-if="hasNewSignupFlow"/> -->
    <div class="container container-wide">
      <div class="widget-filter">

        <ul>
          <li @click="changeViewStatus('all')" :class="viewStatus === 'all' ? 'active' : ''">
            All
          </li>
           <li @click="changeViewStatus('draft')" :class="viewStatus === 'draft' ? 'active' : ''">
            Draft
          </li>
           <li @click="changeViewStatus('review')" :class="viewStatus === 'review' ? 'active' : ''">
            In Review
          </li>
          <li @click="changeViewStatus('published')" :class="viewStatus === 'published' ? 'active' : ''">
            Published
          </li>
          <li @click="changeViewStatus('collaborating')" :class="viewStatus === 'collaborating' ? 'active' : ''">
            Collaborating
          </li>
         
          <li @click="changeViewStatus('deleted')" :class="viewStatus === 'deleted' ? 'active' : ''">
            Deleted
          </li>
        </ul>

      </div>




      <div class="row">

        <div class="col-12">
          <div class="widget">
            <!-- <div class="widget-splash">
                <img>
            </div> -->
            <div class="widget-header sticky">
                <h4><b>Thesis Topics</b></h4>
                <div class="widget-navigation">
                    <router-link to="/collaboration/new" class="widget-nav"><i class="fas fa-plus"></i>Create Thesis Topic</router-link>
                </div>
            </div>
            <div class="widget-body">
              <Loader :times="2" v-if="isLoading"></Loader>
              <template v-if="collaborationsByUser && collaborationsByUser.length > 0 && !isLoading">
                <router-link :to="'/collaborations/' + collaboration._id.$oid" class="erow link row d-flex" v-for="(collaboration, index) in collaborationsByUser" :key="index">
                    <div class="col-1 align-self-center">
                        <div class="align-self-center">
                            <i class="fas fa-hands-helping" ></i>
                        </div>
                    </div>
                    <div class="col-6 align-self-center"> 
                        <div>Thesis title</div>
                        <div class="headline">
                          {{ collaboration.title }}
                        </div>
                    </div>
                    <div class="col-2 align-self-center">
                        <div>Status</div>
                        <div class="headline">{{ collaboration.status ? collaboration.status.replace('review','In review')  :'n/a'}}</div>
                    </div>
                    <div class="col-2 align-self-center">
                        <div>Created</div>
                        <div class="headline">{{ collaboration.createdDate ? getCreatedDate(collaboration.createdDate) : 'n/a' }}</div>
                    </div>

                    <div class="col-1 align-self-center text-right">
                        <!-- <router-link :to="'/collaboration/edit/' + id" class="color-grey" v-if="collaboration.status !== 'collaborating'"><i class="fas fa-pen"></i></router-link> -->
                        <!-- <router-link :to="'/collaborations/' + id" class="color-first ml-3"> -->
                        
                        <span class="btn btn-sm btn-primary">View</span>
                        <!-- <i class="fas fa-eye"></i> -->
                        <!-- </router-link> -->
                    </div>
                </router-link>
                </template>
                <div class="erow row d-flex" v-if="collaborationsByUser && collaborationsByUser.length === 0 && !isLoading">
                      <div class="col-12 align-self-center text-center">
                          <div class="align-self-center">
                            <div class="headline"><i class="fas fa-hands-helping" ></i> No results in '{{ viewStatus }}'</div>
                          </div>
                      </div>
                  </div>
 

            </div>
            <!-- <div class="widget-footer">
                [ CTA LINK TO SUBJECT CONTENT ]
            </div> -->
        </div>


        <!-- STUDENT COLLABORATION INVITES -->
        <div v-if="collaborationInvites">
            <div class="widget">

                <div class="widget-header">
                    <h4>Invites</h4>
                    <div class="widget-navigation">
                        <!-- <router-link :to="'/collaboration/my'" class="widget-nav"><i class="fas fa-link"></i>My collaborations</router-link> -->
                    </div>
                </div>
                <div class="widget-body">
                    <router-link :to="'/collaborations/' + invite.collaborationId" v-for="(invite, index) in collaborationInvites" :key="index"  class="erow row link d-flex">
                        <div class="col-1 align-self-center">
                            <i class="fas fa-envelope"></i>
                        </div>
                        <div class="col-4 align-self-center">
                            <div class="">From</div>
                            <div class="headline">{{ invite.sender }}</div>
                        </div>
                        <div class="col-6 align-self-center">
                            <div class="">Thesis title</div>
                            <div class="headline">{{ invite.collaborationTitle }}</div>
                        </div>
                        <div class="col-1 align-self-center text-right">
                          <span class="btn btn-link">View</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        </div>

      </div>
    </div>
  </div> 
</template>

<script>
import {mapState} from 'vuex'
import { db } from '../../firebase'
import moment from 'moment'
import MasterHeader from '@/components/MasterHeader.vue'
import SignUpModal from '../../components/signUpModal.vue'
import apiInstance from '../../axios/axios'
import Loader from '../../components/Loader.vue'
import { base64ToString } from '../../utils/utils'
import collaborationService from '../../services/collaborationService'

// const userId = localStorage.getItem('uid')
let userId = localStorage.getItem('uid')
userId = base64ToString(userId)
let userVerified = localStorage.getItem('u-verified')
let userType = localStorage.getItem('i_am')
userType = base64ToString(userType)

export default {

  data() {
      return {
        viewStatus: 'all',
        collaborationInvites: null,
      }
  },
  components: {
    MasterHeader,
    Loader
},


  mounted(){
        if ((userVerified == 0 || !userVerified) && userType == 'student') {
      this.$router.push('/user/student-welcome')
    }
    if (userVerified == 0 && userType == 'professional') {
      this.$router.push('/user/welcome')
    }
    this.$store.dispatch('resetCollaborationLoadingState');
    if(this.$store.getters.userCollaborations.length === 0){
      this.$store.dispatch('getAllUserCollaborations', userId)
    }
  },
  // watch:{
  //   viewStatus(val) {
  //     if (val !== 'all'){
  //       return this.collaborationsByUser = this.$store.getters.userCollaborations.filter(collaboration => collaboration.status === val)
  //     }
  //   }
  // },
  computed: {
    ...mapState(['user']),
        hasNewSignupFlow(){
      const vm = this
      const isValid = vm.$store.getters.currentProgress >= 0 ? true : false
      const isStudent = vm.$store.getters.role === 'Student'
        if (!isValid && isStudent) {
          localStorage.setItem('notSignedUp', 1);
        }
        if (isValid) {
          localStorage.removeItem('notSignedUp');
        }
      return true
    },
    isLoading(){
      return this.$store.getters.collaborationIsLoading
    },
    collaborationsByUser(){
      let list = []
      if(this.$store.getters.userCollaborations && this.$store.getters.userCollaborations.length >0) {
        if(this.viewStatus === 'all') {
          list = this.$store.getters.userCollaborations
        }else {
           list = this.$store.getters.userCollaborations.filter(item => {
            return item.status.toLowerCase() === this.viewStatus.toLowerCase()
          })
        }
      }
      return list
    },
    // collaborationsByUser: function() {
      // const collection = this.$store.state.collaboration.usersCollaborations;
      // var result = [];
      // var status = this.viewStatus;
      // if(status === "all") {
      //   result = collection;
      // } else {
      //   result = collection.filter(item => {
      //     return item.collaboration.status === status;
      //   });
      // }
    //   return true;
    // },
    collaborations: function () {
      // return this.$store.state.collaboration.usersCollaborations;
      return true;
    }
  },
  created() {
      // this.$store.dispatch('getCollaborationByUserId', this.user);
      // this.$store.dispatch('getCollaborationInvitesByUserId');
          collaborationService.getUserInvitations().then((res) => {
      this.collaborationInvites = res
    }).catch(() => {
      this.invitationFetchError = 'Failed to fetch invitations...'
    })
  },
  methods: {
    getCreatedDate(date) {
      return moment(date).format('DD-MM-YYYY')
    },
    collaborationMembersFromIds(membersArray) {
      // const result = [];

      // membersArray.forEach(function(memberId, index) {

      //   var ref = db.collection("users").doc(memberId);
      //   ref.get()
      //   .then(function(querySnapshot) {
      //     const collection = querySnapshot.data();
      //     result.push({ "id":querySnapshot.id, "members": querySnapshot.data()});
      //   })
      //   .catch(function(error) {
      //       console.log("Error getting documents: ", error);
      //   });

      // });

      // return result;
      return true;
    },

    changeViewStatus(status) {
      this.viewStatus = status;
      return true;
    },

    moment: function (seconds) {
      // return moment.unix(seconds);
      return true;
    },
    cutText: function(text, characters) {
        return text.length > characters ? text.substring(0, characters) + '...' : text;
    } 
  }
}
</script>

<style>

</style>